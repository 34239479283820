import React, { useContext, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ParagraphText } from '../../assets/GlobalStyle'
import { GlobalContext } from '../../context/global-context'
import { getBlogs } from '../../redux/apiCalls'
import DisqusBlog from './DisqusBlog'

const Container = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-right: 1rem;
    border-right-color: ${props => props.theme.text};

    h2 {
        font-size: 27px;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        color: ${props => props.theme.text};
    }
`
const Block = styled.div`
  background-color: ${props => props.theme.neutral};
`

const Featured = () => {
  const { theme } = useContext(GlobalContext);
  const { blog, isFetching } = useSelector(state => state.blog);
  const { blogs, isFetching: isFetchingBlogs } = useSelector(state => state.blogs);

  const [data, setData] = useState({});

  const { id } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    if(id === undefined) {
      getBlogs(dispatch);
      setData(blogs[0]);
    }
    else {
      setData(blog);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  
  if (isFetching || isFetchingBlogs) {
    return (
      <Container className='border-r-0 md:border-r-[1px] animate-pulse'>
        <div className='flex flex-col gap-y-2'>
          <Block className='w-16 h-3'/>
          <Block className='w-1/2 h-3'/>
        </div>
        <Block className='w-full h-[50vh] rounded-md' />
        <Block className='w-full h-20'/>
      </Container>
    )
  }

  if (id === undefined) {
    return (
      <Container className='border-r-0 md:border-r-[1px]'>
        <div>
            <ParagraphText className='text-[18px]' style={{color: `${theme.fade}`}}>{data.writer}</ParagraphText>
            <h2>{data.title}</h2>
        </div>
        <img src={data.image} alt={data.title} className="w-11/12 h-[60vh] object-cover rounded-md" />
        <ParagraphText className='text-[18px] font-light font-roboto'>{data.content}</ParagraphText>
        <DisqusBlog />
      </Container>
    )
  }

  return (
    <Container className='border-r-0 md:border-r-[1px]'>
      <div>
          <ParagraphText className='text-[18px]' style={{color: `${theme.fade}`}}>{blog.writer}</ParagraphText>
          <h2>{blog.title}</h2>
      </div>
      <img src={blog.image} alt={blog.title} className="w-full h-auto object-cover rounded-md" />
      <ParagraphText className='text-[18px] font-light font-roboto'>{blog.content}</ParagraphText>
      <DisqusBlog />
    </Container>
  )
}

export default Featured