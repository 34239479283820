import React from 'react'
import styled from 'styled-components'

const Shine = styled.span`
  position: absolute;
  top: -0.5rem;
  left: -46%;
  transform: translateX(-50%);
  width: 20px;
  height: 124%;
  transform: rotate(22deg);
  background-color: ${props => props.suppress ? '#ffffff' : props => props.theme.red_secondary}80;
  transition: 0.8s;
  z-index: 1;
`
const BtnText = styled.span`
  position: relative;
  z-index: 10;
`
const Btn = styled.button`
  background-color: ${props => props.suppress ? props.theme.body_secondary : props.theme.red_primary};
  color: ${props => props.suppress ? props.theme.red_primary : props.theme.body_primary};
  border: none;
  font-size: 18px;
  width: 150px;
  height: 45px;
  font-weight: 400;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  position: relative;
  overflow: hidden;

  &:hover ${Shine} {
    left: 46%;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.fade};
    color: ${props => props.theme.text};
  }
`

const Button = ({ content, suppress, onClick, disabled }) => {
  return (
    <Btn suppress={suppress} onClick={onClick} disabled={disabled}>
        <BtnText>
            {content}
        </BtnText>
        <Shine suppress={suppress}/>
    </Btn>
  )
}

export default Button