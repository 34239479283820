import React, { useContext } from 'react'
import Disqus from "disqus-react"
import { GlobalContext } from '../../context/global-context'

const disqusShortname = "sunwayshow"
const disqusConfig = {
  url: "http://localhost:3000",
  identifier: "article-id",
  title: "Sunway Show Discussions"
}

const DisqusBlog = () => {
  const { theme } = useContext(GlobalContext);
  return (
    <div className='w-full flex flex-col gap-y-8 '>
      <Disqus.DiscussionEmbed
        className="w-full px-4 py-2 selection:border-none focus:border-none"
        style={{color: `${theme.primary}`}}    
        shortname={disqusShortname}
        config={disqusConfig}
      />
    </div>
  )
}

export default DisqusBlog