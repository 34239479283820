import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ParagraphText } from '../../assets/GlobalStyle'
import { getBlog } from '../../redux/apiCalls'

export const data = [
    {
        id: 1,
        date: '21st May, 2022',
        title: 'This is the title for the blog',
        image: 'https://i.pinimg.com/474x/82/54/88/825488f7859acb3fac46bae10607618a.jpg',
        desc: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
    },
    {
        id: 2,
        date: '18th May, 2022',
        title: 'Blog 1',
        image: 'https://i.pinimg.com/474x/c0/88/7c/c0887cd35a7f0ebbe1d43453d2d3e061.jpg',
        desc: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
    },
    {
        id: 3,
        date: '17th May, 2022',
        title: 'Blog 2',
        image: 'https://i.pinimg.com/474x/e4/70/2a/e4702ae1e3f2f505a093a120a70a4d20.jpg',
        desc: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
    },
    {
        id: 4,
        date: '10th May, 2022',
        title: 'KEION',
        image: 'https://i.pinimg.com/236x/65/88/22/658822e0baef2cf6f8ec7b26531fa9ef.jpg',
        desc: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
    },
    {
        id: 5,
        date: '17th May, 2022',
        title: 'Designs',
        image: 'https://i.pinimg.com/236x/54/4f/cf/544fcf7ca5398f857f17db07b011ad1d.jpg',
        desc: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
    },
]

const Container = styled.section`
    flex: 0.5;
    flex-direction: column;
    row-gap: 1rem; 
    overflow-y: auto;
`
const Block = styled.div`
  background-color: ${props => props.theme.neutral};
`

const Others = () => {
    const { blogs, isFetching } = useSelector(state => state.blogs);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleNavigate = (id) => {
      getBlog(dispatch, id);
      navigate(`/blogs/${id}`);
    }

    if (isFetching) {
        return (
            <div className='hidden flex-[0.5] md:flex flex-col gap-y-2'>
                <ParagraphText className='text-lg font-medium uppercase'>Other articles</ParagraphText>
                <Container className='hidden md:flex' />
                <div className='w-full grid grid-cols-2 gap-4'>
                    {
                        [0, 1, 2, 3].map(index => (
                            <Block key={index} className='w-52 h-36 rounded' />
                        )) 
                    }
                </div>
            </div>    
        )
    }
    return (
        <div className='hidden flex-[0.5] md:flex flex-col gap-y-2'>
            <ParagraphText className='text-lg font-medium uppercase'>Other articles</ParagraphText>
            <Container className='hidden md:flex'>
                <div className='w-full grid grid-cols-2 gap-4'>
                    {
                        blogs.map((item, index) => (
                            <img src={item.image} alt={item.title} className="w-52 h-36 object-cover rounded" key={index} onClick={() => handleNavigate(item._id)}/>
                        ))
                    }
                </div>
            </Container>
        </div>
    )
}

export default Others