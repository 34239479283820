import { createSlice } from "@reduxjs/toolkit/dist";

const initialState = {
    shorts: [],
    isFetching: false,
    error: false,
}

const shortsSlice = createSlice({
    name: "shorts",
    initialState,
    reducers: {
        fetchShortsStart: (state) => {
            state.isFetching = true;
        },
        fetchShortsSuccess: (state, action) => {
            state.isFetching = false;
            state.shorts = action.payload;
        },
        fetchShortsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        }
    }
})

export const { fetchShortsStart, fetchShortsSuccess, fetchShortsFailure } = shortsSlice.actions;
export default shortsSlice.reducer;