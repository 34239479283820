import React from 'react'
import { FaInstagram, FaLinkedin } from 'react-icons/fa'
import styled from 'styled-components'

const Container = styled.section`
    width: 100%;
    display: flex;
    gap: 2rem;
    align-items: center;    
    margin: 2rem 0;

    p {
        font-size: 18px;
        font-weight: 300;
        color: ${props => props.theme.subText};
    }
`
const BigText = styled.h2`
    font-size: 27px;
    font-weight: ${props => props.strong ? '500' : '300'};
    color: ${props => props.strong ? props.theme.red_primary : props.theme.text};
`

const CEO = ({ img, name, position, linkedIn, insta }) => {
  return (
    <Container>
        <img src={img} alt='CEO' className='w-56 h-64 rounded-[8px]' />
        <div className='flex flex-col gap-y-4'>
            <div className='flex flex-col leading-8'>
                <BigText strong={false}>{name}</BigText>
                <BigText strong={true}>{position}</BigText>
            </div>
            <div className='flex flex-wrap gap-x-4'>
                <FaLinkedin className='text-[27px]' onClick={() => window.open(linkedIn, "_blank")}/>
                <FaInstagram className='text-[27px]' onClick={() => window.open(insta, "_blank")}/>
            </div>
        </div>
    </Container>
  )
}

export default CEO