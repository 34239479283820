import { createSlice } from "@reduxjs/toolkit/dist";

const initialState = {
    blog: [
        {
            title: "",
            writer: "",
            content: "",
            image: "",
        }
    ],
    isFetching: false,
    error: false,
}

const singleBlogSlice = createSlice({
    name: "blog",
    initialState,
    reducers: {
        fetchBlogStart: (state) => {
            state.isFetching = true;
        },
        fetchBlogSuccess: (state, action) => {
            state.isFetching = false;
            state.blog = action.payload;
        },
        fetchBlogFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        }
    }
})

export const { fetchBlogStart, fetchBlogSuccess, fetchBlogFailure } = singleBlogSlice.actions;
export default singleBlogSlice.reducer;