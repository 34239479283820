import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getEpisode } from '../../redux/apiCalls';

const Container = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 1.5rem;

    h1 {
        font-size: 18px;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
        text-transform: uppercase;
    }
`
const P = styled.p`
    color: ${props => props.theme.subText};
`

const OtherStreams = () => {
    const { series, isFetching } = useSelector(state => state.series);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleNavigate = (id) => {
        getEpisode(dispatch, id);
        navigate(`/playlist/series/${id}`);
    }

    return (
        <Container>
            <h1>More from Playlist</h1>
            {
                isFetching &&
                <div>
                    Loading..
                </div>
            }
            <div className='flex flex-col gap-y-4'>
                {
                    series.map((data, index) => (
                        <div key={index} className='flex gap-x-2' onClick={() => handleNavigate(data._id)}>
                            <img src={data.image} alt={data.title} className='w-32 h-32 rounded object-cover' />
                            <div className='flex flex-col'>
                                <h4 className='text-[18px] font-medium truncate'>{data.title}</h4>
                                <P className='text-[18px] font-light'>{(data.episode).length} Episodes</P>
                            </div>
                        </div>  
                    ))
                }
            </div>
        </Container>
    )
}

export default OtherStreams