import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;

    gap: 2rem;

    h1 {
        font-size: 27px;
        font-weight: 800;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;

        span {
            font-size: 18px;
            font-weight: 300;
            font-family: 'Roboto', sans-serif;
            padding-left: 1rem;
        }
    }
`
const EpisodeList = styled.div`
    background-color: ${props => props.selected ? props.theme.red_primary : props.theme.body_secondary};
    color: ${props => props.selected ? props.theme.body_primary : props.theme.text};
    cursor: pointer;
`
const Blank = styled.div`
    background-color: ${props => props.theme.body_secondary};
`

const EpisodeStream = () => {
    const { video } = useSelector(state => state.video);
    const { episode, isFetching } = useSelector(state => state.episode);

    const { id } = useParams();

    // find name of series
    const getSeriesName = () => {
        for (let i = 0; i < video.length; i++) {
            for (let j = 0; j < video[i].series.length; j++) {

                if ((video[i].series[j].ser._id).toString() === id ) {
                    return video[i].series[j].ser.title;
                }
            }
        }
    }

    const [selectedEpisode, setSelectedEpisode] = useState(0);
    const getSelectedEpisode = (epis) => {
        if (selectedEpisode === epis) {
            console.log(episode[epis].title)
            return true;
        }
        else {
            return false;
        }
    }
  
    if (isFetching) {
        return (
            <Container>
                <h1>Loading.....</h1>
            </Container>
        )
    }

    if (episode.length === 0 || episode === undefined) {
        return (
            <Container>
                <Blank className='w-full h-[28vh] lg:h-[62vh] md:h-[35vh] flex justify-center items-center rounded-[8px]'>
                    <p>Looks like someone forgot to upload any episode</p>
                </Blank>
            </Container>
        )
    }

    else {
        return (
            <Container>
                <h1>{getSeriesName()}<span>Episode - {episode[selectedEpisode].title}</span></h1>
                <div className='w-full h-[28vh] lg:h-[62vh] md:h-[35vh]'>
                    <iframe 
                        className='w-full h-full rounded-[4px] lg:rounded-[8px] focus:outline-none selection:outline-none' 
                        src={episode[selectedEpisode].url} 
                        title={episode[selectedEpisode].ep} 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen" 
                        msallowfullscreen="msallowfullscreen" 
                        oallowfullscreen="oallowfullscreen" 
                        webkitallowfullscreen="webkitallowfullscreen"
                    />
                    </div>
                <div className='w-full flex gap-4'>
                    {
                        episode.map((episode, index) => (
                            <EpisodeList key={index} className='py-2 px-6 rounded' selected={getSelectedEpisode(index)} onClick={() => setSelectedEpisode(index)}>{index}</EpisodeList>
                        ))
                    }
                </div>
            </Container>
        )
    }
}

export default EpisodeStream