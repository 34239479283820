import React from 'react'
import styled from 'styled-components';
import Sunway from '../../assets/partners/sunway.png';
import HeadingText from '../HeadingText';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    h1 {
        font-size: 72px;
        font-weight: 800;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 0.75rem;
        text-transform: uppercase;
    }
`

const Partners = () => {
  return (
    <Container>
        <HeadingText title='MEET OUR PARTNERS' visibility={true}/>
        <div className='flex gap-x-8 items-center'>
            <img src={Sunway} className='h-32 w-auto' alt='sunway'/>
        </div>
    </Container>
  )
}

export default Partners