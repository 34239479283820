import React from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import EpisodeStream from '../components/EpisodesComponents/EpisodeStream'
import OtherStreams from '../components/EpisodesComponents/OtherStreams'

const Container = styled.article`
    width: 100%;
    display: flex;
    gap: 3rem;
`
const Recommendations = styled.div`
    border-left: 1px solid ${props => props.theme.text};

    @media (max-width: 430px) {
        border-left: none;
        border-top: 1px solid ${props => props.theme.text};
    }
`

const Episodes = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <Container className='px-4 xl:px-0 flex-col md:flex-row'>
            <div className='w-full md:w-2/3 lg:w-3/4'> {/* Main Series */}
                <EpisodeStream />
            </div>
            <Recommendations className='w-full md:w-[25%] lg:w-[25%]'> {/* Recommendations */}
                <OtherStreams />
            </Recommendations>
        </Container>
    )
}

export default Episodes