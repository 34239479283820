import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
// import ParticlesBg from '../components/ParticlesBg'

import { useDispatch, useSelector } from 'react-redux';
import { createCollab } from '../redux/apiCalls';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = styled.article`
    width: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`
const Wrapper = styled.div`
    background-color: ${props => props.theme.body_secondary}80;
    border-radius: 8px;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 4rem;
    z-index: 9;
    backdrop-filter: blur(4px);

    h1{
        font-size: 27px;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        color: ${props => props.theme.text};
    }

    form {
        label {
            font-weight: 300;
            color: ${props => props.theme.fade};
        }
        input, textarea {
            background-color: transparent;
            border-color: ${props => props.theme.fade};
            border-radius: 4px;
            border-width: 0.5px;
            padding: 0.5rem;
            font-size: 18px;
            font-weight: 300;
            font-family: 'Roboto', sans-serif;
            color: ${props => props.theme.text};
            
            &:focus {
                outline: none;
                border-color: ${props => props.theme.text};
            }   
            &:select {
                border-color: ${props => props.theme.red_primary};
            }
        }
        textarea {
            max-width: 100%;
            min-width: 100%;
            min-height: 8rem;
        }
    }
`

const Collaborate = () => {
    const { isFetching } = useSelector(state => state.collabForm);

    const [fullname, setFullNmae] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [organization, setOrganization] = useState('');
    const [designation, setDesignation] = useState('');
    const [note, setNote] = useState('');

    const [status, setStatus] = useState(false);

    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();
        createCollab(dispatch, { fullname, email, contact, organization, designation, note }, setStatus).then(() => {
            console.log(status);
            if(status === true) {
                setFullNmae('');
                setEmail('');
                setContact('');
                setOrganization('');
                setDesignation('');
                setNote('');
            }
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Container>
            {/* <ParticlesBg /> */}
                <Wrapper>
                    <h1>COLLABORATE</h1>
                    <form className='flex flex-col gap-y-4'>
                        <div className='flex flex-col gap-y-1'>
                            <label type="text">Full Name</label>
                            <input type="text" value={fullname} onChange={(e) => setFullNmae(e.target.value)} />
                        </div>
                        <div className='flex flex-col gap-y-1'>
                            <label type="email">Email</label>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className='flex flex-col gap-y-1'>
                            <label type="number">Contact</label>
                            <input type="number" value={contact} onChange={(e) => setContact(e.target.value)} />
                        </div>
                        <div className='flex flex-col gap-y-1'>
                            <label type="text">Organization</label>
                            <input type="text" value={organization} onChange={(e) => setOrganization(e.target.value)} />
                        </div>
                        <div className='flex flex-col gap-y-1'>
                            <label type="text">Designation</label>
                            <input type="text" value={designation} onChange={(e) => setDesignation(e.target.value)} />
                        </div>
                        <div className='flex flex-col gap-y-1'>
                            <label type="text">Note</label>
                            <textarea type="text" value={note} onChange={(e) => setNote(e.target.value)} />
                        </div>
                        {
                            isFetching ?
                            <Button content='Sending...' disabled={true} />
                            :
                            <Button content='Submit' suppress={false} disabled={false} onClick={handleSubmit}/>
                        }
                    </form>
                </Wrapper>
                <ToastContainer 
                    autoClose={5000} 
                    position="top-center"
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
        </Container>
    )
}

export default Collaborate