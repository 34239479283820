import { createSlice } from "@reduxjs/toolkit/dist";

const initialState = {
    collabForm: {},
    isFetching: false,
    error: false,
}

const collabSlice = createSlice({
    name: "collabForm",
    initialState,
    reducers: {
        postFormStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        postFormSuccess: (state, action) => {
            state.isFetching = false;
            state.collabForm = action.payload;
            state.error = false;
        },
        postFormFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        }
    }
})

export const { postFormStart, postFormSuccess, postFormFailure } = collabSlice.actions;
export default collabSlice.reducer;