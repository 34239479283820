import React from 'react'
import styled from 'styled-components'
import { FaInstagram, FaLinkedin } from 'react-icons/fa'

const Text = styled.p`
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: ${props => props.strong ? props.theme.red_primary : props.theme.text};
    font-weight: ${props => props.strong ? '500' : '300'};
`

const Members = ({ img, name, position, linkedIn, insta }) => {
  return (
    <div className='w-56 flex flex-col gap-y-2'>
        <img src={img} alt={name} className='w-full h-64 rounded object-cover'/>
        <div>
            <Text strong={false}>{name}</Text>
            <Text strong={true}>{position}</Text>
        </div>
        <div className='flex flex-wrap gap-x-4'>
          <FaLinkedin className='text-[27px]' onClick={() => window.open(linkedIn, "_blank")}/>
          <FaInstagram className='text-[27px]' onClick={() => window.open(insta, "_blank")}/>
        </div>
    </div>
  )
}

export default Members