import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import HeadingText from '../HeadingText';
import Cards from './Cards';

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";
import { getEpisode } from '../../redux/apiCalls';
import { useDispatch } from 'react-redux';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const Lists = ({ name, playlistData }) => {
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const handleNavigate = (id) => {
    getEpisode(dispatch, id);
    navigate('/playlist/series/' + id);
  }

  return (
    <Container>
        <HeadingText title={name} />

        <Swiper
          slidesPerView={4}
          slidesPerGroup={4}
          spaceBetween={30}
          loop={true}
          loopFillGroupWithBlank={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper w-full hidden lg:block"
        >
          {
            playlistData.map((item, index) => (
              <SwiperSlide key={index}>
                <Cards key={index} title={item.ser.title} img={item.ser.image} count={(item.ser.episode).length} handleClick={() => handleNavigate(item.ser._id)} />
              </SwiperSlide>
            ))
          }
        </Swiper>

        <Swiper
          slidesPerView={2}
          slidesPerGroup={2}
          spaceBetween={30}
          loop={true}
          loopFillGroupWithBlank={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper w-full hidden md:block lg:hidden"
        >
          {
            playlistData.map((item, index) => (
              <SwiperSlide key={index}>
                <Cards key={index} name={item.ser.title} img={item.ser.image} count={(item.ser.episode).length} handleClick={() => handleNavigate(item.ser._id)} />
              </SwiperSlide>
            ))
          }
        </Swiper>

        <Swiper
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={30}
          loop={true}
          loopFillGroupWithBlank={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper w-full md:hidden"
        >
          {
            playlistData.map((item, index) => (
              <SwiperSlide key={index}>
                <Cards key={index} name={item.ser.title} img={item.ser.image} count={(item.ser.episode).length} handleClick={() => handleNavigate(item.ser._id)} />
              </SwiperSlide>
            ))
          }
        </Swiper>
    </Container>
  )
}

export default Lists