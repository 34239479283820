import React from 'react'
import styled from 'styled-components'
import { FaLinkedinIn } from 'react-icons/fa'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  height: 100%;
  background-color: ${props => props.theme.body_primary};
`
const Text = styled.p`
    font-size: 18px;
    font-weight: ${props => props.type==='focused' ? 400 : 300};
    color: ${props => props.type==='focused' ? props.theme.red_primary : props.theme.fade};
`

const TeamCard = ({img, name, position, handleLink }) => {
  return (
    <Container className='w-60'>
        <img src={img} alt={name} className='w-60 h-60 object-cover object-top rounded-md' />
        <div className='flex flex-col'>
            <Text type='faded'>{name}</Text>
            <Text type='focused'>{position}</Text>
        </div>
        <FaLinkedinIn className='text-[2rem]' onClick={handleLink}/>
    </Container>
  )
}

export default TeamCard