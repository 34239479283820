import React, { useEffect } from 'react'
import styled from 'styled-components'
import Blogs from '../components/LandingComponents/Blogs/Blogs'
import Footer from '../components/LandingComponents/Footer/Footer'
import Partners from '../components/LandingComponents/Partners'
import Playlist from '../components/LandingComponents/Playlists/Playlist'
import Team from '../components/LandingComponents/Teams/Team'
import Welcome from '../components/LandingComponents/Welcome'

const Container = styled.main`
  width: 100%;
  background-color: ${props => props.theme.body_primary};
  display: flex;
  flex-direction: column;
  gap: 4rem;
`

const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <Welcome />
      <Playlist />
      <Blogs />
      <Partners />     
      <Team /> 
      <Footer />
    </Container>
  )
}

export default Landing