import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.nav`
    width: 100%;
    margin-top: 1rem;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transform: translateX(${props => props.translateX});
    transition: transform 1s ease-in-out;
`
const Lists = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    padding: 0 1rem;
    color: ${props => props.theme.text};
`

const DropNav = ({ translate, opacity}) => {

    return (
        <Container translateX={translate}>
            <Lists opacity={opacity}>
                <Link to='/team'><li>team</li></Link>
                <Link to='/playlist'><li>playlist</li></Link>
                <Link to='/blogs'><li>blogs</li></Link>
                <Link to='/collaborate'><li>collaborate</li></Link>
            </Lists>
        </Container>
    )
}

export default DropNav