import { createSlice } from "@reduxjs/toolkit/dist";

const initialState = {
    blogs: [
        {
            title: "",
            writer: "",
            content: "",
            image: "",
        }
    ],
    isFetching: false,
    error: false,
}

const blogSlice = createSlice({
    name: "blogs",
    initialState,
    reducers: {
        fetchBlogsStart: (state) => {
            state.isFetching = true;
        },
        fetchBlogsSuccess: (state, action) => {
            state.isFetching = false;
            state.blogs = action.payload;
        },
        fetchBlogsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        }
    }
})

export const { fetchBlogsStart, fetchBlogsSuccess, fetchBlogsFailure } = blogSlice.actions;
export default blogSlice.reducer;