import React from 'react'
import styledComponents from 'styled-components'

import Featured from './Featured'
import Others from './Others'
import ResponsiveBlogs from './ResponsiveBlogs'

const Container = styledComponents.div`
    display: flex;
    column-gap: 1rem;
`

const Blogs = () => {
  return (
    <Container className='flex-col md:flex-row'>
        <ResponsiveBlogs />
        <Featured />
        <Others />
    </Container>
  )
}

export default Blogs