import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components'
import { Link } from 'react-scroll'

import { FaSpotify, FaYoutube } from 'react-icons/fa'
import { SiGooglepodcasts } from 'react-icons/si'

import Expression from '../../assets/icons/Expression.png'
import Button from '../Button'
import { getShorts } from '../../redux/apiCalls'
import { HamroPatro } from '..';

const Container = styled.main`
  width: 100%;
  background-color: ${props => props.theme.body_primary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 18px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;

    @media (max-width: 1000px) {
      font-size: 21px;
    }
  }
`
const HeadingText = styled.h1`
  font-size: 72px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  letter-spacing: 0.75rem;

  @media (max-width: 1000px) {
    font-size: 48px;
  }
`
const SocialWrapper = styled.div`
  border-color: ${props => props.theme.text};
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
`
const Icon = styled.div`
  font-size: 27px;
  color: ${props => props.theme.text};

  &:hover {
    color: ${(props) => props.color};
  }
`
const Block = styled.div`
  background-color: ${props => props.theme.neutral};
`

const Welcome = () => {
  const { shorts, isFetching } = useSelector(state => state.shorts);

  const dispatch = useDispatch();
  useEffect(() => {
    getShorts(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useNavigate();

  return (
    <Container>
      <div className='flex flex-col mt-16 items-center gap-y-16'>
        <div className='flex flex-col items-center'>
          <div className='relative w-full md:w-max h-max'>
            <HeadingText className='w-full md:w-auto flex text-center leading-[2.75rem] md:leading-none'>
              THE SUNWAY SHOW
            </HeadingText>
            <img src={Expression} alt='expression' className='hidden md:flex absolute -right-16 -top-4 w-14 lg:w-20 h-auto' />
          </div>
          {/* <p className='mt-4'>Description text goes here......</p> */}

          <div className='mt-8 flex gap-x-8'>
            <Link to="explore" smooth={true}><Button content='Explore' suppress={true} disabled={false} /></Link>
            <Button content='Play' suppress={false} disabled={false} onClick={() => navigate('/playlist')}/>
          </div>
        </div>

        {/* Images */}
        <div className='mt-8 flex flex-wrap md:flex-nowrap gap-8 px-4 lg:px-0'>
          {
            isFetching ? 
            <>
              {
                [0, 1, 2].map(i => (
                  <div className='relative w-full md:w-52 h-96 animate-pulse'>
                    <Block className='w-full h-full rounded-md'/>
                  </div>
                ))
              }
            </>
            :
            <>
              {
                shorts.length > 0 &&
                <>
                  {
                    shorts.slice(0, 3).map((short, index) => (
                      <div className='relative w-full md:w-52 h-96' key={index}>
                        <iframe 
                          src={short.url}
                          title={short.title} 
                          frameborder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                          allowfullscreen
                          className='w-full h-full rounded-md focus:outline-none'
                        />
                      </div>
                    ))
                  }
                </>
              }
            </>
          }
        </div>

        {/* Social */}
        <SocialWrapper className='border-y-2'>
          <Icon color='#1DB954'><FaSpotify onClick={() => window.open("https://open.spotify.com/show/7d9v2MhUM0sVGOdObB3EM9" , "_blank")}/></Icon>
          <Icon color='#FF0000'><FaYoutube onClick={() => window.open("https://www.youtube.com/channel/UCQer3a3G5hlxRjoMlV8IhnA" , "_blank")}/></Icon>
          <Icon color='#F4B400'><SiGooglepodcasts onClick={() => window.open("https://podcasts.google.com/feed/aHR0cHM6Ly9oYW1yb2F1ZGlvLmFwcHNwb3QuY29tL2ZlZWQvcG9kY2FzdC81NzIzMjMwMDA0MzE0MTEy" , "_blank")}/></Icon>
          <img 
            src={HamroPatro} 
            alt='Hamro Podcast' 
            className='w-7 h-7 rounded-sm grayscale hover:grayscale-0' 
            onClick={() => window.open("https://podcasts.hamropatro.com/podcast/5649064945254400" , "_blank")}
          />
        </SocialWrapper>
      </div>
    </Container>
  )
}

export default Welcome