import React, { useContext } from 'react'
import styled from 'styled-components'
import Hamburger from 'hamburger-react'

import Logo from '../../assets/logo.png'
import Button from '../Button'
import { GlobalContext } from '../../context/global-context'
import DropNav from './DropNav'
import { Link } from 'react-router-dom'

const RootContainer = styled.div`
  max-width: 100vw;
  padding: 1rem;
  background-color: ${props => props.theme.body_primary};
  border-bottom: 1px solid ${props => props.theme.text};
  position: sticky;
  top: 0;
  z-index: 10;
`
const Nav = styled.nav`
  max-width: 1366px;
  margin: 0 auto;
  background-color: ${props => props.theme.body_primary};
  color: ${props => props.theme.text};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul{
    flex: 1;
    list-style: none;
    column-gap: 2rem;

    li {
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      text-transform: uppercase;

      &:hover {
        color: ${props => props.theme.red_primary};
      }
    }
  }
`

const Navbar = () => {
  const { isOpen, setOpen, setTranslate, height, opacity } = useContext(GlobalContext)
  
  return (
    <RootContainer>
      <Nav>
          <ul className='hidden lg:flex'>
            <Link to='/team'><li>team</li></Link>
            <Link to='/playlist'><li>playlist</li></Link>
            <Link to='/blogs'><li>blogs</li></Link>
          </ul>

          <div className='flex-1 flex lg:justify-center'>
            <Link to='/'>
              <img src={Logo} alt='SunwayShow' className='w-24 h-auto' />
            </Link>
          </div>

          <div className='hidden flex-1 lg:flex justify-end'>
            <Link to='/collaborate'>
              <Button content='COLLABORATE' suppress={true} disabled={false} />
            </Link>
          </div>

          <div className='flex-1 flex justify-end lg:hidden'>
            <Hamburger toggled={isOpen} toggle={setOpen} onTootle={toogled => {
                if (toogled) {
                    setTranslate('0%')
                } else {
                    setTranslate('-20%')
                }
            }}/>
          </div>
      </Nav>
      { isOpen &&
        <DropNav height={height} opacity={opacity} />
      }
    </RootContainer>
  )
}

export default Navbar