import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import HeadingText from '../../HeadingText'
import BlogCards from './BlogCards'

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";

import { useEffect } from 'react'
import { getBlog, getBlogs } from '../../../redux/apiCalls'

const Container = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`
const Block = styled.div`
  background-color: ${props => props.theme.neutral};
`

const Blogs = () => {
  const { blogs, isFetching } = useSelector(state => state.blogs);

  const dispatch = useDispatch();
  useEffect(() => {
    getBlogs(dispatch);
  }, [dispatch]);

  const navigate = useNavigate();
  const handleNavigate = (id) => {
    getBlog(dispatch, id);
    navigate(`/blogs/${id}`);
  }

  if (isFetching) {
    return (
      <Container className='px-4 xl:px-0'>
        <HeadingText title="GET UPDATED" visibility={true}/>
        <Swiper
          slidesPerView={4}
          spaceBetween={30}
          className="mySwiper w-full hidden lg:block"
        >
          <SwiperSlide>
            <div className='flex flex-col gap-y-3 w-full h-96 animate-pulse'>
              <Block className='w-full h-72 rounded-md' />
              <div className='flex flex-col gap-y-2'>
                <Block className='w-full h-3'/>
                <Block className='w-full h-3'/>
                <Block className='w-1/2 h-8'/>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </Container>
    )
  }

  return (
    <Container className='px-4 xl:px-0'>
        <HeadingText title='GET UPDATED' visibility={true}/>

        <Swiper
          slidesPerView={4}
          slidesPerGroup={4}
          spaceBetween={30}
          loop={true}
          loopFillGroupWithBlank={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper w-full hidden lg:block"
        >
          {
            blogs.map((item, index) => (
              <SwiperSlide key={index}>
                <BlogCards name={item.title} img={item.image} desc={item.content} handleNavigate={() => handleNavigate(item._id)}/>
              </SwiperSlide>
            ))
          }
        </Swiper>

        <Swiper
          slidesPerView={2}
          slidesPerGroup={2}
          spaceBetween={30}
          loop={true}
          loopFillGroupWithBlank={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper w-full hidden md:block lg:hidden"
        >
          {
            blogs.map((item, index) => (
              <SwiperSlide key={index}>
                <BlogCards name={item.title} img={item.image} desc={item.content} handleNavigate={() => handleNavigate(item._id)}/>
              </SwiperSlide>
            ))
          }
        </Swiper>

        <Swiper
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={30}
          loop={true}
          loopFillGroupWithBlank={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper w-full md:hidden"
        >
          {
            blogs.map((item, index) => (
              <SwiperSlide key={index}>
                <BlogCards name={item.title} img={item.image} desc={item.content} handleNavigate={() => handleNavigate(item._id)}/>
              </SwiperSlide>
            ))
          }
        </Swiper>
    </Container>
  )
}

export default Blogs