import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Logo from '../../../assets/logo.png'

const Container = styled.footer`
    width: 100%;
    height: fit-content;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    border-top: 0.5px solid ${props => props.theme.fade};

    ul {
        li {
            color: ${props => props.theme.fade};
            cursor: pointer;

            &:hover {
                color: ${props => props.theme.text};
            }
        }
    }
`
const Copyright = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    color: ${props => props.theme.red_primary};

    border-top: 0.5px solid ${props => props.theme.fade};
`

const Footer = () => {
  return (
    <Container>
        <img src={Logo} alt="logo" className='h-32 w-auto'/>
        <ul className='w-full flex flex-col md:flex-row justify-center items-center gap-8 list-none'>
            <Link to='/playlist'><li>Playlists</li></Link>
            <Link to='/blogs'><li>Blogs</li></Link>
            <Link to='/team'><li>Teams</li></Link>
            <Link to='/collaborate'><li>Collaborate</li></Link>
            {/* <li>Terms and Conditions</li>
            <li>Privacy Policy</li> */}
        </ul>

        <Copyright>
            <p>Copyright © 2022. All rights reserved.</p>
            <p>Managed by TWB Creates</p>
        </Copyright>
    </Container>
  )
}

export default Footer