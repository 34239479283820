import React from 'react'
import styled from 'styled-components'
import { RiPlayListFill } from 'react-icons/ri'

const Container = styled.section`
    width: 18rem;
    height: 24rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    h2 {
        font-size: 18px;
        color: ${props => props.theme.text};
        font-weight: 500;
    }
`
const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 80%;

    div {
        z-index: 8;
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: ${props => props.theme.text}99;
        backdrop-filter: saturate(30%) blur(2px) contrast(80%);
        -moz-backdrop-filter: saturate(30%) blur(2px) contrast(80%);
        color: ${props => props.theme.body_primary};
    }
    
`

const Cards = ({ img, title, count, handleClick }) => {
  return (
    <Container className='w-full h-[24rem] md:w-[18rem]' onClick={handleClick}>
        <Wrapper>
            <img src={img} alt={title} className='w-full h-full rounded object-cover'/>
            <div>
                <p className='text-[27px] font-normal'>{count}</p>
                <p className='text-[18px] font-light flex items-center gap-x-2'><RiPlayListFill className='text-[1rem]' />  Episodes</p>
            </div>
        </Wrapper>
        <h2>{title}</h2>
    </Container>
  )
}

export default Cards