import React from 'react'
import styled from 'styled-components';
import HeadingText from '../../HeadingText';
import TeamCard from './TeamCard';

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import { EffectCreative, Pagination } from "swiper";
import { Ksitiz, Dikshya, Nimesh, Pramod, Reshma, Rohil, Saroj, Suyog, Diwash, Simoli } from '../..';

export const teamData = [
    {
        id: 1,
        name: 'Promod Poudel',
        title: 'Host',
        img: Pramod,
        linkedin: 'https://www.linkedin.com/in/pramodpoudel/',
        facebook: 'https://www.facebook.com/Pramod-Poudel-105781938687454',
        insta: 'https://www.instagram.com/pramodpoudel_/'
    },
    {
        id: 2,
        name: 'Diwash Pradhan',
        title: 'Host',
        img: Diwash,
        linkedin: 'https://www.linkedin.com/in/diwaspradhan/',
    },
    {
        id: 3,
        name: 'Simoli Desai',
        title: 'Host',
        img: Simoli,
        linkedin: 'https://www.linkedin.com/in/simoli-desai-37a9b782/',
    },
    {
        id: 4,
        name: 'Ksitiz Devkota',
        title: 'Manager',
        img: Ksitiz,
        linkedin: 'https://www.linkedin.com/in/ksitiz-devkota-b556aa135/',
        facebook: 'https://www.facebook.com/kdevrex/',
        insta: 'https://www.instagram.com/twb_creates/'
    },
    {
        id: 7,
        name: 'Reshma Oli',
        title: 'Communication & Operation Head',
        img: Reshma,
        linkedin: 'https://www.linkedin.com/mwlite/in/reshma-oli-59930818b?fbclid=IwAR2YiiM6hhzeh7a_ST0pnMKgFyls4aM0xFdfhIZ1xwca6kJKOPMFyjsHwkI',
        facebook: 'https://www.facebook.com/olireshma',
        insta: 'https://www.instagram.com/twb_creates/'
    },
    {
        id: 10,
        name: 'Dikshya Kharel',
        title: 'Creative & SEO Head',
        img: Dikshya,
        linkedin: 'https://www.linkedin.com/in/manash-prajapati/',
    },
    {
        id: 5,
        name: 'Saroj Ghimire',
        title: 'Graphic Designer',
        img: Saroj,
        linkedin: 'https://www.linkedin.com/in/sarozz-ghimire-3a1669236/',
        facebook: 'https://www.facebook.com/sarozz.ghimire',
        insta: 'https://www.instagram.com/twb_creates/'
    },
    {
        id: 6,
        name: 'Rohil Rai',
        title: 'Editor',
        img: Rohil,
        linkedin: 'https://www.linkedin.com/in/rohil-rai-06656b239/',
        facebook: 'https://www.facebook.com/rohil.rai.54',
        insta: 'https://www.instagram.com/twb_creates/'
    },
    {
        id: 8,
        name: 'Suyog Karki',
        title: 'Web Developer',
        img: Suyog,
        linkedin: 'https://www.linkedin.com/in/suyog-karki-82286a235/',
        facebook: 'https://www.facebook.com/profile.php?id=100016191664267',
        insta: 'https://www.instagram.com/twb_creates/'
    },
    {
        id: 9,
        name: 'Nimesh Shakya',
        title: 'Web Developer',
        img: Nimesh,
        linkedin: 'https://www.linkedin.com/in/nimesh-shakya-4966451b6/',
        facebook: 'https://www.facebook.com/nimesh.shakya.14/',
        insta: 'https://www.instagram.com/twb_creates/'
    },
]

const Container = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
`

const Team = () => {
    return (
        <Container className='px-4 xl:px-0 overflow-x-hidden'>
            <HeadingText title='MEET THE TEAM' visibility={true}/>

            <Wrapper className='hidden invisible md:visible md:flex'>
                {
                    teamData.map((team, index) => (
                        <TeamCard key={index} img={team.img} name={team.name} position={team.title} handleLink={() => window.open("https://www.linkedin.com/in/pramodpoudel/", "_blank")}/>
                    ))
                }
            </Wrapper>

            <Swiper
                grabCursor={true}
                effect={"creative"}
                loop={true}
                pagination={true}
                creativeEffect={{
                prev: {
                    shadow: true,
                    origin: "left center",
                    translate: ["-5%", 0, -200],
                    rotate: [0, 100, 0],
                },
                next: {
                    origin: "right center",
                    translate: ["5%", 0, -200],
                    rotate: [0, -100, 0],
                },
                }}
                modules={[EffectCreative, Pagination]}
                className="mySwiper6 w-full md:hidden"
            >
                {
                    teamData.map((team, index) => (
                        <SwiperSlide key={index}>
                            <TeamCard img={team.img} name={team.name} position={team.title}/>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </Container>
    )
}

export default Team