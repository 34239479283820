import axiosInstance from "../utils/axios.config";
import { postFormFailure, postFormStart, postFormSuccess } from "./features/collabSlice";
import { fetchEpisodeFailure, fetchEpisodeStart, fetchEpisodeSuccess } from "./features/episodeSlice";
import { fetchPlaylistStart, fetchPlaylistSuccess, fetchPlaylistFailure } from "./features/playlistSlice";
import { fetchSeriesFailure, fetchSeriesStart, fetchSeriesSuccess } from "./features/seriesSlice";
import { fetchVideoFailure, fetchVideoStart, fetchVideoSuccess } from "./features/videoSlice";
import { fetchBlogsFailure, fetchBlogsStart, fetchBlogsSuccess } from "./features/blogSlice";

import { toast } from "react-toastify";
import { fetchBlogFailure, fetchBlogStart, fetchBlogSuccess } from "./features/singleBlogSlice";
import { fetchShortsFailure, fetchShortsStart, fetchShortsSuccess } from "./features/shortsSlice";

export const getAllVideo = async (dispatch) => {
    dispatch(fetchVideoStart());
    try {
        const response = await axiosInstance.get("/video/everything");
        dispatch(fetchVideoSuccess(response.data.everything));
    }
    catch (error) {
        dispatch(fetchVideoFailure());
    }
}

export const getAllSeries = async (dispatch) => {
    dispatch(fetchSeriesStart());
    try {
        const response = await axiosInstance.get("/video/get_all_series");
        dispatch(fetchSeriesSuccess(response.data.series));
    }
    catch (error) {
        dispatch(fetchSeriesFailure());
    }
}

export const getPlaylist = async (dispatch) => {
    dispatch(fetchPlaylistStart());
    try {
        const response = await axiosInstance.get("video/get_playlists");
        dispatch(fetchPlaylistSuccess(response.data.playlists));
    }
    catch (error) {
        dispatch(fetchPlaylistFailure());
    }
}

export const getSeries = async (dispatch, id) => {
    dispatch(fetchSeriesStart());
    try {
        const response = await axiosInstance.get(`video/get_series/${id}`);
        dispatch(fetchSeriesSuccess(response.data.series));
    }
    catch(err) {
        dispatch(fetchSeriesFailure());
    }
}

export const getEpisode = async (dispatch, id, setEpisodeData) => {
    dispatch(fetchEpisodeStart());
    try {
        const response = await axiosInstance.get(`video/get_episodes/${id}`);
        dispatch(fetchEpisodeSuccess(response.data.episodes));
        setEpisodeData(response.data.episodes);
        console.log(response.data.episodes);
    }
    catch(err) {
        dispatch(fetchEpisodeFailure());
    }
}

export const createCollab = async (dispatch, data, setStatus) => {
    dispatch(postFormStart());
    try {
        const response = await axiosInstance.post("/home/new_colab", data);
        dispatch(postFormSuccess(response.data));
        setStatus(true);
        toast.success("Request sent successfully");
    }
    catch (err) {
        dispatch(postFormFailure());
        toast.error(err.response.data.error.split(':')[2]);
    }
}

export const getBlogs = async (dispatch) => {
    dispatch(fetchBlogsStart());
    try{
        const response = await axiosInstance.get("/home/get_blogs");
        dispatch(fetchBlogsSuccess(response.data.blogs));
    }
    catch(err) {
        dispatch(fetchBlogsFailure())
    }
}
export const getBlog = async (dispatch, id) => {
    dispatch(fetchBlogStart());
    try{
        const response = await axiosInstance.get(`/home/get_blog/${id}`);
        dispatch(fetchBlogSuccess(response.data.blog));
    }
    catch(err) {
        dispatch(fetchBlogFailure())
    }
}

export const getShorts = async (dispatch) => {
    dispatch(fetchShortsStart());
    try{
        const response = await axiosInstance.get("/video/get_shorts");
        dispatch(fetchShortsSuccess(response.data.shorts));
    }
    catch(err) {
        dispatch(fetchShortsFailure());
    }
}