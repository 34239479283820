import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 1rem;

    h2 {
        font-size: 36px;
        font-weight: 800;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 0.75rem;
        text-transform: uppercase;
    }
    div {
        background-color: ${props => props.theme.red_primary};
    }
`


const HeadingText = ({ title }) => {
  return (
    <Container>
        <h2 className='w-fit'>{title}</h2>
        <div className='flex-grow h-[1px]'/>
    </Container>
  )
}

export default HeadingText