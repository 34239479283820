import { createSlice } from "@reduxjs/toolkit/dist";

const initialState = {
    series: [],
    isFetching: false,
    error: false,
}

const seriesSlice = createSlice({
    name: "series",
    initialState,
    reducers: {
        fetchSeriesStart: (state) => {
            state.isFetching = true;
        },
        fetchSeriesSuccess: (state, action) => {
            state.isFetching = false;
            state.series = action.payload;
        },
        fetchSeriesFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        }
    }
})

export const { fetchSeriesStart, fetchSeriesSuccess, fetchSeriesFailure } = seriesSlice.actions;
export default seriesSlice.reducer;