import React, { useContext, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styled, { ThemeProvider } from 'styled-components'
import Landing from './pages/Landing'
import Navbar from './components/NavbarComponents/Navbar'
import { GlobalContext } from './context/global-context'
import Playlists from './pages/Playlists'
import Episodes from './pages/Episodes'
import Collaborate from './pages/Collaborate'
import Team from './pages/Team'
import Blogs from './pages/Blogs'
import { getAllSeries, getAllVideo } from './redux/apiCalls'

const RootContainer = styled.main`
  max-width: 100vw;
  background-color: ${props => props.theme.body_primary};
  color: ${props => props.theme.text};
`
const Container = styled.section`
  max-width: 1366px;
  margin: 0 auto;
  padding: 3rem 1rem;
  min-height: 100vh;
  color: ${props => props.theme.text};
  font-family: 'Roboto', sans-serif;
  font-size: 18px;

  @media (max-width: 100px) {
    width: 100%;
  }
`

const App = () => {
    const { theme } = useContext(GlobalContext);

    const dispatch = useDispatch();
    useEffect(() => {
        getAllVideo(dispatch);
        getAllSeries(dispatch);
    }, [dispatch])

    return (
      <ThemeProvider theme={theme}>
          <Router>
            <Navbar />
            <RootContainer>
              <Container>
                  <Routes>
                    <Route path='/' element={<Landing />} />
                    <Route path='/playlist' element={<Playlists />} />
                    <Route path='/playlist/series/:id' element={<Episodes />} />
                    <Route path='/collaborate' element={<Collaborate />} />
                    <Route path='/team' element={<Team />} />
                    <Route path='/blogs' element={<Blogs />} />
                    <Route path='/blogs/:id' element={<Blogs />} />
                  </Routes>
              </Container>
            </RootContainer>
          </Router>
      </ThemeProvider>
    )
}

export default App