import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components'
import PlaylistCards from './PlaylistCards'
import HeadingText from '../../HeadingText';
import { getEpisode } from '../../../redux/apiCalls';

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";
import { useNavigate } from 'react-router-dom';

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`
const Skeleton = styled.div`
  div {
    background-color: ${props => props.theme.neutral};
  }
`

const Playlist = () => {
  const { series, isFetching } = useSelector(state => state.series);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleNavigate = (id) => {
    getEpisode(dispatch, id);
    navigate('/playlist/series/' + id);
  }

  if (isFetching) {
    return (
      <Container className='px-4 xl:px-0'>
        <HeadingText title="LISTEN TO US" visibility={true} />

        <Swiper spaceBetween={30} slidesPerView={4} navigation={true} pagination={true} className="mySwiper w-full hidden lg:block">
          <SwiperSlide>
            <Skeleton className='flex flex-col gap-3 w-full h-[24rem]'>
              <div className='w-full h-72 rounded-md animate-pulse' />
              <div className='w-1/2 h-8 rounded animate-pulse' />                  
              <div className='w-2/3 h-12 rounded-md animate-pulse' />                  
            </Skeleton>
          </SwiperSlide>
        </Swiper>
      </Container>
    )
  }

  return (
    <Container className='px-4 xl:px-0' id='explore'>
        <HeadingText title='LISTEN TO US' visibility={true}/>
        
        <Swiper
          slidesPerView={4}
          slidesPerGroup={4}
          spaceBetween={30}
          loop={false}
          loopFillGroupWithBlank={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper w-full hidden lg:block"
        >
          {
            series.map((item, index) => (
              <SwiperSlide key={index}>
                <PlaylistCards 
                  key={index} 
                  name={item.title} 
                  img={item.image} 
                  desc={`${(item.episode).length} Episodes`}
                  handleView = {() => handleNavigate(item._id)}  
                />
              </SwiperSlide>
            ))
          }
        </Swiper>

        <Swiper
          slidesPerView={2}
          slidesPerGroup={2}
          spaceBetween={30}
          loop={true}
          loopFillGroupWithBlank={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper w-full hidden md:block lg:hidden"
        >
          {
            series.map((item, index) => (
              <SwiperSlide key={index}>
                <PlaylistCards 
                  key={index} 
                  name={item.title} 
                  img={item.image} 
                  desc={`${(item.episode).length} Episodes`}
                  handleView = {() => handleNavigate(item._id)}  
                />
              </SwiperSlide>
            ))
          }
        </Swiper>

        <Swiper
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={30}
          loop={true}
          loopFillGroupWithBlank={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper w-full md:hidden"
        >
          {
            series.map((item, index) => (
              <SwiperSlide key={index}>
                <PlaylistCards 
                  key={index} 
                  name={item.title} 
                  img={item.image} 
                  desc={`${(item.episode).length} Episodes`}
                  handleView = {() => handleNavigate(item._id)}  
                />
              </SwiperSlide>
            ))
          }
        </Swiper>
    </Container>
  )
}

export default Playlist