import styled from 'styled-components';

export const HeadingText = styled.h1`
    font-family: 'Poppins', sans-serif;
    color: ${props => props.theme.text};
    font-size: 27px;
`
export const ParagraphText = styled.p`
    font-family: 'Roboto', sans-serif;
    color: ${props => props.strong ? props.theme.text : props.theme.subText};

    span{
        color: ${props => props.theme.red_primary}
    }
`
