import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Lists from '../components/PlaylistComponents/Lists'

export const playlistData = [
    {
        _id: 1,
        name: 'The Best Year',
        img: 'https://images.unsplash.com/photo-1655720837928-38b1a93298ac?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw3fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
        series: [
            {
                _id: 11,
                name: 'Cooperation Event',
                img: 'https://images.unsplash.com/photo-1655720837928-38b1a93298ac?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw3fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
                episodes: [
                    {
                        _id: 111,
                        name: 'Episode 1',
                        img: 'https://images.unsplash.com/photo-1655438819172-3951bc9b9db2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
                        video_src: 'https://www.youtube.com/embed/sqlZwS-8VUM'
                    },
                    {
                        _id: 112,
                        name: 'Episode 2',
                        img: 'https://images.unsplash.com/photo-1655701120735-5177560204e0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyNHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
                        video_src: 'https://www.youtube.com/embed/Ra1Fsa9YJCk'
                    },
                    {
                        _id: 113,
                        name: 'Episode 3',
                        img: 'https://images.unsplash.com/photo-1655803242400-c74124e94ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyN3x8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
                        video_src: 'https://www.youtube.com/embed/4i--jCilG8E'
                    },
                ]
            },
            {
                _id: 12,
                name: 'Pave the Path',
                img: 'https://i.pinimg.com/564x/32/01/aa/3201aa17c1dc8b393822c9ffbef064f4.jpg',
                episodes: [
                    {
                        _id: 111,
                        name: 'Episode 1',
                        img: 'https://images.unsplash.com/photo-1655438819172-3951bc9b9db2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
                        video_src: 'https://www.youtube.com/embed/sqlZwS-8VUM'
                    },
                    {
                        _id: 112,
                        name: 'Episode 2',
                        img: 'https://images.unsplash.com/photo-1655701120735-5177560204e0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyNHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
                        video_src: 'https://www.youtube.com/embed/Ra1Fsa9YJCk'
                    },
                ]
            },
            {
                _id: 13,
                name: 'The Unexpected',
                img: 'https://i.pinimg.com/236x/2c/c5/af/2cc5af607a6350b9360ddb8e1ad12cff.jpg',
                episodes: [
                    {
                        _id: 111,
                        name: 'Episode 1',
                        img: 'https://images.unsplash.com/photo-1655438819172-3951bc9b9db2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
                        video_src: 'https://www.youtube.com/embed/sqlZwS-8VUM'
                    },
                    {
                        _id: 112,
                        name: 'Episode 2',
                        img: 'https://images.unsplash.com/photo-1655701120735-5177560204e0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyNHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
                        video_src: 'https://www.youtube.com/embed/Ra1Fsa9YJCk'
                    },
                ]
            },
            {
                _id: 14,
                name: 'Rebuild',
                img: 'https://i.pinimg.com/236x/f0/08/e3/f008e3ceabbfa202d1f8b3bc2473f838.jpg',
                episodes: [
                    {
                        _id: 111,
                        name: 'Episode 1',
                        img: 'https://images.unsplash.com/photo-1655438819172-3951bc9b9db2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
                        video_src: 'https://www.youtube.com/embed/sqlZwS-8VUM'
                    },
                    {
                        _id: 112,
                        name: 'Episode 2',
                        img: 'https://images.unsplash.com/photo-1655701120735-5177560204e0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyNHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
                        video_src: 'https://www.youtube.com/embed/Ra1Fsa9YJCk'
                    },
                ]
            },
            {
                _id: 15,
                name: 'End?',
                img: 'https://i.pinimg.com/236x/cf/1b/64/cf1b647b925d8f07c0badc671052009f.jpg',
                episodes: [
                    {
                        _id: 111,
                        name: 'Episode 1',
                        img: 'https://images.unsplash.com/photo-1655438819172-3951bc9b9db2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
                        video_src: 'https://www.youtube.com/embed/sqlZwS-8VUM'
                    },
                    {
                        _id: 112,
                        name: 'Episode 2',
                        img: 'https://images.unsplash.com/photo-1655701120735-5177560204e0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyNHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
                        video_src: 'https://www.youtube.com/embed/Ra1Fsa9YJCk'
                    },
                ]
            },
        ]
    },
    {
        _id: 2,
        name: 'New Wave',
        series: [
            {
                _id: 21,
                name: 'Cooperation Event',
                img: 'https://images.unsplash.com/photo-1655815226512-d630272d19a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzMHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
                episodes: [
                    {
                        _id: 211,
                        name: 'Episode 1',
                        img: 'https://images.unsplash.com/photo-1655438819172-3951bc9b9db2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
                        video_src: 'https://www.youtube.com/embed/sqlZwS-8VUM'
                    },
                ]
            },
        ]
    },

]

const Container = styled.main`
  width: 100%;
  min-height: 100vh;
  background-color: ${props => props.theme.body_primary};
  display: flex;
  flex-direction: column;
  gap: 4rem;

  h1 {
    font-size: 72px;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.75rem;
    text-transform: uppercase;
  }
`

const Playlists = () => {
    const { video } = useSelector(state => state.video)
    const [playlists, setPlaylists] = useState([])

    useEffect(() => {
        setPlaylists(video)
    }, [video])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Container className='px-4 xl:px-0'>
            <h1>Playlists</h1>
            {
                playlists.map((playlist, index) => (
                    <Lists name={playlist.title} playlistData={playlist.series} key={index} />
                ))
            }
        </Container>
    )
}

export default Playlists