import React, { useEffect } from 'react'
import styled from 'styled-components'
import Blog from '../components/BlogComponents/Blog'
import Header from '../components/BlogComponents/Header'

const Container = styled.main`
  width: 100%;
  background-color: ${props => props.theme.body};
  margin: 0 auto;

  h1 {
    font-size: 72px;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.75rem;
    text-transform: uppercase;
  } 
`

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container className='w-full px-4 xl:px-0'>
        <h1>BLOGS</h1>
        <div className='flex flex-col gap-y-16'>
            <Header />
            <Blog />
        </div>
    </Container>
  )
}

export default Blogs