import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux'

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";

import { ParagraphText } from '../../assets/GlobalStyle';
import { getBlog } from '../../redux/apiCalls';

const Container = styled.div`
    width: 100%;
    flex-direction: column; 
    row-gap: 1rem;
    margin-bottom: 2rem;
    overflow-y: auto;
`
const Block = styled.div`
  background-color: ${props => props.theme.neutral};
`

const ResponsiveBlogs = () => {
    const { blogs, isFetching } = useSelector(state => state.blogs);

    const dispatch = useDispatch();
    console.log(blogs)

    const navigate = useNavigate();
    const handleNavigate = (id) => {
      getBlog(dispatch, id);
      navigate(`/blogs/${id}`);
    }

    if (isFetching) {
        return (
            <div className='flex-[0.5] flex flex-col gap-y-2 md:hidden'>
                <ParagraphText className='text-lg font-medium uppercase'>Other articles</ParagraphText>
                <Container className='hidden md:flex' />
                <div className='w-full grid grid-cols-2 gap-4'>
                    {
                        [0, 1, 2, 3].map(index => (
                            <Block key={index} className='w-52 h-36 rounded' />
                        )) 
                    }
                </div>
            </div>    
        )
    }
    return (
        <div className='w-full flex flex-col md:hidden'>
            <ParagraphText className='text-lg font-medium uppercase mb-2 md:hidden'>Other articles</ParagraphText>
            <Container className='flex md:hidden'>
                <Swiper
                    slidesPerView={2}
                    slidesPerGroup={2}
                    spaceBetween={30}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper w-full flex"
                >
                    {
                        blogs.map((item, index) => (
                            <SwiperSlide key={index}>
                                <img src={item.image} alt={item.title} className="w-60 h-40 rounded object-cover" key={index} onClick={() => handleNavigate(item._id)}/>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>   
            </Container>
        </div>
    )
}

export default ResponsiveBlogs
