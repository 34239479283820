import React from 'react'
import styled from 'styled-components'
import Button from '../../Button'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
    background-color: ${props => props.theme.body_primary};
`
const Text = styled.p`
    font-size: 18px;
    font-weight: ${props => props.type==='focused' ? 400 : 300};
    color: ${props => props.type==='focused' ? props.theme.text : props.theme.fade};
`

const BlogCards = ({ name, img, desc, handleNavigate }) => {
  return (
    <Container>
        <img src={img} alt={name} className='w-full h-72 object-cover object-top rounded-md' />
        <div className='flex flex-col'>
            <Text type='focused'>{name}</Text>
            <Text type='faded' className='truncate'>{desc}</Text>
        </div>
        <Button suppress={false} content='View Blog' disabled={false} onClick={handleNavigate}/>
    </Container>
  )
}

export default BlogCards