import React, { useContext, useState } from 'react'
import styledComponents from 'styled-components'
import { ParagraphText } from '../../assets/GlobalStyle'
import { FaSearch } from 'react-icons/fa'
import { GlobalContext } from '../../context/global-context'

const SearchWrapper = styledComponents.div`
    background-color: ${props => props.theme.text};
    border-radius: 4px;
    display: flex;
    padding: 0 0.5rem;
    align-items: center;
    color: ${props => props.theme.body_primary};
    transition: all 0.3s ease-in-out;

    input {
        &:focus {
            outline: none;
        }
        &:selection {
            outline: none;
        }
    }
`

const Header = () => {
  const { theme } = useContext(GlobalContext)
  const [expand, setExpand] = useState(false);

  return (
    <div className='w-full mt-16 flex justify-between border-b-[1px] py-2 mx-auto' style={{borderBottomColor: `${theme.text}`}}>
        <ParagraphText>DISCUSSIONS</ParagraphText>
        {
            !expand ?
            (
                <SearchWrapper className='w-8 h-8 justify-center' onClick={() => setExpand(true)}>
                    <FaSearch className='text-lg'/>
                </SearchWrapper>
            )
            :
            (
                <SearchWrapper className='w-56 h-8 justify-between'>
                    <input className='w-40 bg-transparent border-0 focus:border-0 selection:border-0' />
                    <FaSearch className='text-lg'/>
                </SearchWrapper>
            )
        }
    </div>
  )
}

export default Header