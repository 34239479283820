import React, { useEffect } from 'react'
import styled from 'styled-components'
import { teamData } from '../components/LandingComponents/Teams/Team'
import CEO from '../components/TeamComponents/CEO'
import Members from '../components/TeamComponents/Members'

const Container = styled.article`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.body_primary};

    h1 {
        font-size: 72px;
        font-weight: 800;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 0.75rem;
        text-transform: uppercase;
    }
`

const getCEODetails = () => {
    return teamData.filter(person => person.title === 'Host')
}
let CEO_Details = getCEODetails()[0];

const getMembers = () => {
    return teamData.filter(person => person.title !== 'Host')
}
let membersData = getMembers();


const Team = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Container>
            <h1>TEAM</h1>
            <div className='flex flex-col gap-y-12'>
                <CEO 
                    img={CEO_Details.img} 
                    name={CEO_Details.name} 
                    position={CEO_Details.title} 
                    linkedIn={CEO_Details.linkedin}
                    facebook={CEO_Details.facebook}
                    insta={CEO_Details.insta}
                />
                <div className='w-full flex flex-wrap gap-12'>
                    {
                        membersData.map((member, index) => (
                            <Members 
                                key={index} 
                                img={member.img} 
                                name={member.name} 
                                position={member.title} 
                                linkedIn={member.linkedin}
                                facebook={member.facebook}
                                insta={member.insta}
                            />
                        ))
                    }
                </div>
            </div>
        </Container>
    )
}

export default Team